nav{
    width: 100%;
    height: 5rem;
    display: grid;
    place-items: center;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000000000;
    transform: var(--transition);
}

/*
nav img{
    display: none;
} */


.nav__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.nav__logo{
    width: 5rem;
    height: 5rem;
    /*overflow: hidden;*/
}

.nav__logo img{
    width: 100%;
    height: 100%;
}

.nav__menu{
    display: flex;
    gap: 1.2rem;
}


#theme__icon{
    background-color: transparent;
    color:black;
    font-size: 2rem;
    cursor: pointer;

}


@media screen and(max-width: 1024px){
    .nav__menu{
        display: none;
    }

}
