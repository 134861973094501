.header__socials {
    display: flex;
    justify-content: space-around; /* Or space-between */
  }


  header{
    margin-top: 50;
    margin-bottom: 4rem;
    display: grid;
    place-items: center;
    background: var(--color-white);
    transition: var(--transition);
    position: relative; /* z-index only works on positioned elements */
    z-index: 9999; /* adjust this value as needed */
  }


  .header__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* font-size: 500px; */
  }

  .header__container > h3{
    font-size: 80px;
  }


  .header__profile img{
    width: 18rem;
    height: 18rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
  }



  .header__cta{
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  #header > p{
    width: 35rem;
        text-align: center;
        margin: 0.2rem 0 2rem;
  }


  @media screen and (max-width: 1024px){
    header{
        margin-top: 0;
    }
  }

  @media screen and (max-width: 600px){
    .header__profile{
        width: 14rem;
        height: 17rem;
        max-width: 60%;
        max-height: 35vh;
    }

    .header__container p{
        width: 100%;
    }

    .header__cta{
        gap:1rem;
    }


  }
